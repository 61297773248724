<template>
  <div class="custom-input custom-email-number-input">
    <label :class="[{'is-not-valid': !email.isValid}, classes || {}]" :style="getStyles">
      <span class="input-label" :class="{'is-focus': email.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>
      <input type="email"
             :class="[{'disabled': disabled}]"
             @input="handleInputValue"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             v-model="email.value">
    </label>
  </div>
</template>

<script>
export default {
  name: 'customEmailInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelPlaceholder: {
      type: String,
      default: ''
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    classes: {
      type: Object,
      default () { return {} }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      email: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.email.isValid = true
    }
  },
  methods: {
    handleInputValue () {
      this.validateValue()
      this.$emit('input', {value: this.email.value, isValid: this.email.isValid})
    },
    validateValue () {
      if (this.isEmpty) {
        this.email.isValid = true
      } else {
        const re = this.$validator('regex.customEmail')
        this.email.isValid = re.test(this.email.value)
      }
    },
    initValues () {
      this.email.value = this.value.value
      this.validateValue()
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-email-number-input {
  position: relative;
  margin: 15px 0;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border: 1px solid #cecece;
    border-radius: .5rem;
    backdrop-filter: sepia(1);
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        background: #ffffff;
        top: -10px;
      }
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none !important;
      direction: rtl;

      &.disabled {
        opacity: 0.75;
      }
    }
  }
}
</style>
